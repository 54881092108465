<template>
  <NavHeader/>
  <div class="about">
    <section id="intro">
      <div class="info">
        <div class="titles">
          <h1>Hi, I'm <strong>Luis Torres.</strong></h1>
          <h4 class="subtitle"> Born in Barquisimeto / <b>Venezuela</b> back in <b>’94</b> </h4>
        </div>
        <p class="description" >I have been passionated about technology for over 15 years. <br><br>
          What started just by been curious about how a NES cartridge works ended up by setting life goals to become a hacker<sup><a href="http://www.catb.org/~esr/faqs/hacker-howto.html">*</a></sup>.
        </p>
      </div>
      <div class="img-container">
        <img src="@/assets/luistorres-keyboard.png" alt="" srcset="">
      </div>
    </section>
  </div>
  <footer class="grey">
    <NavFooter/>
  </footer>
</template>

<script>
// @ is an alias to /src
import NavHeader from '@/components/Navbar.vue'
import NavFooter from '@/components/Footer.vue'

export default {
  name: 'About',
  components: {
    NavHeader,
    NavFooter
  }
}
</script>


<style scoped>

  #intro{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-top: 3rem;
  }

  #intro .info{
    width: 80%;
    max-width: 750px;
    text-align: center;
    padding: 3rem;
  }
  .titles{
    text-shadow: 1px 1px 3px #FFFFFF99;
  }
  h1{
    font-size: 4.2rem;
    font-weight: 300;
    color: #3e3e3e;
    margin-bottom: 0.3em;
  }

  #intro .subtitle {
    font-size: 2rem;
    color: #999;
    font-weight: lighter;
    margin-top: 0;
  }

  .description{
    padding-top: 0.5em ;
    font-size: 2.4rem;
    font-family: 'Open Sans',sans-serif;
    width: 80%;
    margin:auto;
  }

  .grey{
    background-color: #e3e3e3;
  }

  .img-container{
    width: 30%;
    min-width: 350px;
    max-width: 90%;
    display: flex;
    align-items: flex-end;
  }
  .img-container img{
    width: 100%;
   /* filter: drop-shadow(2px 4px 6px black);*/

  }


  @media (max-width: 750px) {

    .titles {
      display: flex;
      flex-flow: column;
      justify-content: center;
      mix-blend-mode: screen;
      height: 85vh;
      box-sizing: border-box;
      padding-bottom: 25%;
      margin-bottom: 20%;
    }

    #intro .info{
      width: 100%;
      max-width: 750px;
      text-align: center;
      padding: 2rem;
    }

    .titles h1{
      font-size: 2.5rem;
    }
    .titles .subtitle{
      font-size: 1.5;
    }

    .info .description {
      font-size: 1.5rem;
      margin: 15% auto;
    }

    #intro .img-container{
      position: absolute;
      top: 8rem;
      z-index: -1;
      width: 100%;
      max-width: 100%;
      height: 85vh;
    }
  }

</style>